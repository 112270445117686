import React from "react";
// import { LoginSuccess } from "../../router/access";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
import md5 from "md5";
import {
  Button,
  IconButton,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import Config from "../../config";
import { AxiosCall } from "../../api";
import { ToastMessage } from "../../components";
import { Routes } from "../../router/routes";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#ECF0F7",
  },
  box: {
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    borderRadius: "16px",
    padding: theme.spacing(3),
  },
}));

const Login = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [showLogin, setShowLogin] = React.useState(false);
  // const [error, setError] = React.useState();
  const [values, setValues] = React.useState({
    email_id: "",
    password: "",
    error: {
      email_id: "",
      password: "",
    },
    message: "",
    showPassword: false,
  });

  React.useEffect(() => {
    // if (getLocalSession("localStorage", "WANTED")) {
    //   history.push(LoginSuccess());
    // }
    //eslint-disable-next-line
    const query = new URLSearchParams(window.location.search);
    const getToken = query.get("token");
    setShowLogin(getToken?.length !== 0 && getToken !== null ? false : true);
  }, []);
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const validate = () => {
    let hasEmail = values.email_id.trim().length === 0 ? false : true;
    let hasPassWord = values.email_id.trim().length === 0 ? false : true;

    if (hasEmail && hasPassWord) {
      return true;
    } else {
      setValues({
        ...values,
        error: { email_id: !hasEmail, password: !hasPassWord },
      });
      return false;
    }
  };

  const onEnterPressed = (e) => {
    if (e.key === "Enter") {
      onLogin();
    }
  };

  const onLogin = async () => {
    if (!validate()) {
      ToastMessage("Required field are empty!", "error", "top-center");
      return false;
    }
    let params = {
      username: values.email_id,
      password: values.password,
    };
    CheckAndGetAccessForUser(params);
  };

  const CheckAndGetAccessForUser = async (params) => {
    let customheader = { tenantid: process.env.REACT_APP_IDM_TENANT_ID };
    let getAccess = await AxiosCall("post", Config.login, params, customheader);
    if (getAccess.Code === "201") {
      let id = getAccess.keyclkId;
      let aTclk = getAccess?.tokenDetails?.access_token;
      let rTclk = getAccess?.tokenDetails?.refresh_token;
      localStorage.setItem("aTclk", aTclk);
      localStorage.setItem("rTclk", rTclk);
      localStorage.setItem("kclkid", id);
      await getNotificationTiming();
      let getRole = await getUserRole(id);
      if (getRole) {
        // let basicRoute = BasicRoute.find((l) => l.roleid === getRole);
        localStorage.setItem("RBAC", btoa(getRole));
        history.push(Routes.home);

        // await getPatientLoggedIn(id,getRole);
        // await getPersonLoggedin(id, getRole);
      } else {
        ToastMessage(
          "No Roles are Mapped to you, Please Contact Admin",
          "error",
          "top-center"
        );
        localStorage.removeItem("aTclk");
        localStorage.removeItem("rTclk");
        localStorage.removeItem("kclkid");
        localStorage.removeItem("RBAC");
      }
    } else if (getAccess.Code === "1111") {
      ToastMessage(getAccess.error, "error", "top-center");
      localStorage.removeItem("aTclk");
      localStorage.removeItem("rTclk");
      localStorage.removeItem("kclkid");
      localStorage.removeItem("RBAC");
    } else {
      ToastMessage("Something Went Wrong !", "error", "top-center");
      localStorage.removeItem("aTclk");
      localStorage.removeItem("rTclk");
      localStorage.removeItem("kclkid");
      localStorage.removeItem("RBAC");
    }
  };

  const getNotificationTiming = async () => {
    let params = {
      db_name: Config.dbname,
      entity: "CodingMaster",
      filter: `CodingMaster._id=='${process.env.REACT_APP_NOTIFICATION_TRIGGER_TIME}' && CodingMaster.status==true && CodingMaster.activestatus==true`,
      return_fields: "CodingMaster",
    };
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );
    let result = res?.result[0];
    let timing = result?.gmconfigvalues?.Timing;

    localStorage.setItem("TriggerTiming", timing);

    // return Number(timing);
  };

  // const getPersonLoggedin=async(id)=>{
  //   try {
  //     let params = {
  //       db_name: "ipmo",
  //       entity: "Person",
  //       filter: `Person.keycloackid=='${id}' && Person.activestatus==true`,
  //       return_fields:
  //         "{rolename:Person.roleid[0].rolename,person:UNSET(document(Person.name[0]),'_key','_id','_rev','createddate','createdby','activestatus','updatedby','updatedate','family','id','given','prefix','use','text','period')}",
  //     };

  //     let res = await AxiosCall(
  //       "post",
  //       `${Config.api_url}/api/read_documents`,
  //       params
  //     );
  //     let result = res?.result[0];
  //     if (result) {
  //       sessionStorage.setItem("userdetails", btoa(result));
  //     }

  //     return result;
  //   } catch (error) {

  //   }
  // }

  const generateCheckSum = async (rol) => {
    let CheckSum = md5(JSON.stringify(rol));
    delete rol.apikey;
    rol.CheckSum = CheckSum;
    fetch(process.env.REACT_APP_IDMJWTTokencreation, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(rol),
    })
      .then((res) => res.json())
      .then((result) => sessionStorage.setItem("queue_Token", result.Result))
      .catch((err) => console.log(err));
  };
  const getUserRole = async (id) => {
    // debugger;
    let param = {
      db_name: process.env.REACT_APP_DB,
      entity: "Person",
      filter: `Person.keycloackid=='${id}' && Person.activestatus==true`,
      return_fields:
        "{roleDetail:Person.roleid[0],_id:Person._id,facility:Person.orgType,OrgId:Person.OrgID[0].id}",
    };
    let role = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      param
    );
    let chesumData = {
      facilityid: role?.result[0]?.roleDetail?.facility
        ? role?.result[0]?.roleDetail?.facility
        : "",
      roleid: role?.result[0]?.roleDetail?.roleid
        ? role?.result[0]?.roleDetail?.roleid
        : "",
      locationid: "",
      personid: role?.result[0]?._id ? role?.result[0]?._id : "",
      enterpriseid: "",
      orgid: role?.result[0]?.roleDetail?.OrgId
        ? role?.result[0]?.roleDetail?.OrgId
        : "",
      apikey: process.env.REACT_APP_APIKEY,
    };
    generateCheckSum(chesumData);

    let _ = role?.result[0]?.roleDetail?.roleid;

    return _;
  };

  return (
    <div className={classes.root}>
      {showLogin && (
        <Box className={classes.box}>
          <Paper elevation={2} className={classes.paper}>
            <div style={{ margin: "8px 0" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  // src="images/kpj-logo.png"
                  src ="images/ainqa logo.png"
                  alt="icon"
                  style={{ width: "80px", height: "80px", objectFit: "cover" }}
                />
              </div>

              <Typography
                style={{ fontWeight: "800" }}
                variant="h6"
                align="center"
              >
                {"MEAL ORDER"}
              </Typography>
            </div>

            <Grid>
              <Grid item xs={12} lg={12}>
                <Typography variant="body2">
                  {"User Id / Bed Number"}{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={values.email_id}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      email_id: e.target.value,
                      error: { ...values.error, email_id: "" },
                      message: "",
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "8px",
                  }}
                >
                  <Typography variant="body2">
                    Password <span style={{ color: "red" }}>*</span>
                  </Typography>
                  {/* For Forgot Password */}
                  {/* <Typography variant="body2" style={{ color: "#007AFF" }}>
                  Forgot Password?
                </Typography> */}
                </div>
                <TextField
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  size="small"
                  fullWidth
                  name="password"
                  margin="dense"
                  variant="outlined"
                  onKeyPress={(e) => onEnterPressed(e)}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      password: e.target.value,
                      error: { ...values.error, password: "" },
                      message: "",
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                          disableRipple={true}
                          disableFocusRipple={true}
                        >
                          {!values.showPassword ? (
                            <VisibilityOffOutlinedIcon />
                          ) : (
                            <VisibilityOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              style={{ margin: "16px 0 0px 0", backgroundColor: "#007AFF" }}
              color="primary"
              onClick={onLogin}
              size="large"
            >
              {"Sign In"}
            </Button>
          </Paper>
        </Box>
      )}
    </div>
  );
};

export default Login;
